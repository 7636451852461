// Colors
$black:             #000000;
$white:             #ffffff;
$main-blue:         #69A6FF;
$main-pink:         #DC2763;
$light-pink:        #fdf4f7;
$main-green:        #006D10;
$light-green:       #f0fff2;

$bonus-yellow:      #FFC75B;
$bonus-green:       #62D783;
$bonus-orange:      #F36D37;
$bonus-blue:        #44BDFF;
$light-blue:        #5AA6FF;
$collapse-blue:     #e5f1ff;
$back-blue:         #cae2ff;
$pending-status-yellow:          #F2C32C;
$authentication-layout-blue:     #eff5ff;

$base-text-color:         #333333;
$button-disabled-gray:    #D5D5D5;
$button-hover-gray:       #ededef;
$checkbox-radio-gray:     #E1E1E1;
$label-gray:              #777777;
$placeholder-gray:        #999999;
$error-red:               #B10000;
$light-red:               #fff0f0;
$bottom-border-gray:      #D8D8D8;
$upload-zone-gray:        #f7f7f7;
$multiselect-gray:        #ececec;
$grey-button-date:        #c5c5c5;
$breadcrum-gray:          #d8d8da;
// Shadows
$component-shadow: 0 2px 6px rgba(160, 160, 160, 0.3);
$box-shadow: 0 2px 10px #9f9f9f;
$authentication-form-shadow: -11px 11px 40px 0 rgba(50,98,169,0.12);
// =============================================================
// Colors
$pink:              $main-pink;
$green:             $bonus-green;
$blue:              $bonus-blue;
$orange:            $bonus-orange;
$yellow:            $bonus-yellow;
$light-yellow:      #fff7e7;
$red:               #fe6c69;
$dark-red:          $error-red;
$light-red:         #fdf1f0;
$blue-gray:         #657ca2;
$dark-grey:         $placeholder-gray;
$grey:              $label-gray;
$bg-grey:           #e9e9e9;
$light-gray:        #f4f4f4;
$border-gray:       $bottom-border-gray;
$secondary-gray:    #e5e8f4;
$active-progress:   $main-blue;
$active-tab-text:   $label-gray;


$white-10: rgba(255, 255, 255, 0.1);
$white-30: rgba(255, 255, 255, 0.3);
$black-005: rgba(0, 0, 0, 0.05);
$black-01: rgba(0, 0, 0, 0.1);
$black-40: rgba(0, 0, 0, 0.4);
$black-80: rgba(0, 0, 0, 0.8);

$colors: (
  black: $black,
  white: $white,
  green: $green,
  blue: $main-blue,
  orange: $orange,
  yellow: $yellow,
  red: $red,
  light-red: $light-red,
  blue-gray: $blue-gray,
  grey: $grey,
  bg-grey: $bg-grey,
  light-gray: $light-gray,
  border-gray: $border-gray,
  secondary-gray: $secondary-gray
);

// Base Font Sizes
$large-size: 20px;
$medium-size: 18px;
$base-size: 14px;
$small-size: 13px;
$tiny-size: 12px;
$extra-tiny-size: 10px;
$sidebar-link-size: 20px;

// Heading Font Sizes
$h1: 22px;
$h2: 18px;
$h3: 16px;
$h4: 15px;
$h5: 16px;
$h6: 14px;

// Media Query Breakpoints
$breakpoints: (
  "xs":   576px,
  "sm":   768px,
  "md":   992px,
  "lg":   1200px,
);

// Component Variables
$header-height: 60px;
$organization-avatar-size: 40px;
$issue-avatar: 40px;
$organization-avatar-small-size: 30px;
$content-new-padding: 20px 30px 20px 30px;
$content-height: calc(100vh - #{$header-height});
$content-breadcrum-height: calc(100vh - #{$header-height} - 40px);
$content-padding: 15px 20px;
$modal-padding: 20px;
$tabs-size: 200px;
$progress-size: 300px;
$info-icon-size: 35px;
$step-icon-size: 36px;
$pagination-btn-size: $step-icon-size;
$content-width: 830px;
$remove-icon-size: 20px;
$closed-issue-icon-size: 26px;
$avatar-size: 40px;

// Shadows:
$wide-shadow: 0 0 10px $black-01;
$header-shadow: 0 -5px 10px $black-005;

//Spacing

$spacing: 8px;
$spacing-2: $spacing * 2;
$spacing-3: $spacing * 3;
$spacing-4: $spacing * 4;

$spacings: (
  x05: 4px,
  x1: 8px,
  x2: 16px,
  x3: 24px,
  x4: 30px,
  x5: 38px,
  x6: 46px,
  x7: 54px,
);
